export const ROUTES = {
  HOME: '/',
  TERMS_CONDITIONS: '/terms-conditions',
  TERMS_OF_USE: '/terms-of-use',
  CORPORATE_ACTIONS: '/corporate-actions',
  CEP: '/corporate-actions-etp',
  CEP_NEW_REQUEST: '/corporate-actions-etp/new-request',
  CET: '/corporate-actions-etf',
  CET_REGULATORY_DOCS: '/corporate-actions-etf/regulatory-documents-list',
  CBO: '/corporate-actions-bonds',
  CBO_NEW_REQUEST: '/corporate-actions-bonds/new-request',
  CEQ: '/corporate-actions-equities',
  CAW: '/corporate-actions-warrants',
  CAW_NEW_REQUEST: '/corporate-actions-warrants/new-request',
  EAD: '/listing-admission',
  RGU: '/risk-guard',
  LOA: '/logical-access',
  MLP: '/mmlp',
  UAM: '/uam',
  UAM_CR: '/uamcr', // TODO: remove it after the UAM release.
  UAM_ACCOUNT: '/uam/users/personal-details',
  BAH: '/bond-admission-home',
  FIA: '/fixed-income-admission',
  SAP: '/structured-debt-admission-dublin',
  SDL: '/structured-debt-admission',
  ETP: '/etp-listing',
  CFT: '/cfts',
  SDA: '/securitized-derivatives-admission',
  MOB: '/onboarding',
};
