import { useCrossWidgetRedirect, useExternalRedirect } from 'App.hooks';
import AppContent from 'components/AppContent/AppContent';
import CommonErrorState from 'components/CommonErrorState/CommonErrorState';
import { Cover, GlobalStyle, LayoutBackground } from 'dap-ui-components';
import { getDecodedIdToken, isAuthenticated, removeMockedTokens, requestSignIn } from 'helpers/authHelper';
import { useCommonCover } from 'helpers/coverHelper';
import { hasMFAClientEnabled, setupAuth } from 'helpers/mfaHelper';
import { getUserData } from 'helpers/userHelper';
import { useTranslate } from 'i18n';
import { FC, useEffect } from 'react';
import { selectEnv, updateUser } from 'store/core/slice';
import { useAppDispatch, useAppSelector } from 'store/types';

const App: FC = () => {
  const t = useTranslate();
  const env = useAppSelector(selectEnv);
  const checkRedirectFromExternalOrigin = useExternalRedirect();
  const { isRedirecting, setRedirecting } = useCrossWidgetRedirect();
  const dispatch = useAppDispatch();

  const startApp = async() => {
    // Clean the mocked tokens if the env doesn't require it
    if (!env.showSettings) {
      removeMockedTokens();
    }

    const decodedIdToken = await getDecodedIdToken();

    if (decodedIdToken) {
      const newUser = getUserData(decodedIdToken);
      dispatch(updateUser(newUser));
      checkRedirectFromExternalOrigin();
      console.info('Session restored.');
    } else {
      console.error('Invalid ID Token.');
    }
  };

  const normalSignIn = () => {
    setRedirecting(true);
    requestSignIn(env);
  };

  const { loading, reloadData, errorMessage } = useCommonCover(async() => {
    // Configure the Amplify Auth lib
    setupAuth(env, hasMFAClientEnabled());

    if (await isAuthenticated()) {
      // Start the normal app flow
      await startApp();
    } else {
      // Redirect to the normal sign-in
      normalSignIn();
      await new Promise(() => undefined); // Keep the loader during the redirect
    }
  });

  useEffect(() => {
    reloadData();
  }, []);

  return (
    <LayoutBackground>
      <GlobalStyle />

      <Cover
        loading={loading || isRedirecting}
        text={t('common.loading')}
        content={<AppContent />}
        errorState={errorMessage && (
          <CommonErrorState
            description={errorMessage}
            onReload={reloadData}
          />
        )}
      />
    </LayoutBackground>
  );
};

export default App;
