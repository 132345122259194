import { Button, ErrorState, LockOutlinedIcon } from 'dap-ui-components';
import { useTranslate } from 'i18n';
import { FC } from 'react';

const AppPermissionError: FC = () => {
  const t = useTranslate();

  return (
    <ErrorState
      icon={LockOutlinedIcon}
      title={t('permissionDenied.title')}
      description={t('permissionDenied.description')}
      actions={(
        <Button
          label={t('permissionDenied.buttonLabel')}
          onClick={() => location.reload()}
        />
      )}
    />
  );
};

export default AppPermissionError;
