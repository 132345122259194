import {
  checkDisclaimerSubmit,
  getAccessToken,
  getDecodedAccessToken,
  getIdToken,
  getIdentityProvider,
  getMockedAccessToken,
  requestSignIn,
} from 'helpers/authHelper';
import { checkMFAScope, requestMFASignIn } from 'helpers/mfaHelper';
import { saveRedirect } from 'helpers/redirectHelper';
import { useState } from 'react';
import { selectEnv, selectUser } from 'store/core/slice';
import { useAppSelector } from 'store/types';
import { AppConfig, WidgetTrigram } from '../../models';

export const useRenderer = (
  appId: WidgetTrigram,
  host: string,
  filterPublicUrl?: boolean,
  name?: string,
  Config?: Record<string, any>,
) => {
  const [isDisclaimerOpen, setDisclaimerOpen] = useState(false);
  const env = useAppSelector(selectEnv);
  const user = useAppSelector(selectUser);
  const containerId = `${name || appId}-container`;
  const hasValidDisclaimer = !env.uamDisclaimerEndpoint || checkDisclaimerSubmit(user);

  const mountMicroFrontend = () => {
    const renderFunction = (window as any)[`render${name}`] || (window as any)[`render${appId}`];

    const checkExpiredToken = (token: string): string => {
    // Trigger a new login when the token is expired
      if (!token) {
        requestSignIn(env);
      }

      return token;
    };

    const integration: AppConfig = {
      ...Config, // TODO: this is passed for backward compatibility, remove it when all the apps will use the standard integration
      host,
      getAccessToken: () => getAccessToken().then(checkExpiredToken),
      getIdToken: () => getIdToken().then(checkExpiredToken),
    };

    if (Config) {
    // TODO: Legacy integration, remove it when all the apps will use the standard integration
    // NOTE: The mocked access token is passed twice because some apps expect it as 3rd parameter and other ones as 4th.
      renderFunction(containerId, integration, getMockedAccessToken(), getMockedAccessToken());
    } else {
    // Standard integration
      renderFunction(containerId, integration);
    }
  };

  const unmount = () => {
    const unmountFunction = (window as any)[`unmount${name}`] || (window as any)[`unmount${appId}`];

    if (unmountFunction) {
      unmountFunction(containerId);
    }
  };

  const appendAssetFile = (sourcePath: string, key: string) => new Promise<void>(resolve => {
  // Remove the PUBLIC_URL from the target manifest files, it it exists
  // The PUBLIC_URL is present in the target manifest to support the test of the target app as a standalone SPA
    const projectFolder = host.substring(host.lastIndexOf('/'));
    if (filterPublicUrl && sourcePath.indexOf(projectFolder) === 0) {
      sourcePath = sourcePath.replace(projectFolder, '');
    }

    const script = document.createElement('script');
    script.id = key;
    script.crossOrigin = '';
    script.onload = () => resolve();
    script.src = `${host}${sourcePath}`;

    document.body.appendChild(script);
  });

  const loadAssets = async() => {
    try {
    // Load the files only if the chuld app is not already mounted
      if (!document.getElementById(`${appId}-main.js`)) {
        const response = await fetch(`${host}/asset-manifest.json?_=${Date.now()}`);
        const manifest = await response.json();

        const assetsQueue = Object.keys(manifest.files)
          .filter(key => key.endsWith('.js'))
          .map(key => appendAssetFile(manifest.files[key], `${appId}-${key}`));

        await Promise.all(assetsQueue);
      }

      mountMicroFrontend();
    } catch (error) {
      console.error(`${appId} boot failed. ${error}`);
    }
  };

  const checkMFA = async(): Promise<boolean> => {
    const hasMFAEnabled = env.mfaEnabledApps && env.mfaEnabledApps.includes(appId);
    const decodedAccessToken = await getDecodedAccessToken();
    const hasMFAScope = checkMFAScope(decodedAccessToken, env.mfaScope);
    return !hasMFAEnabled || hasMFAScope;
  };

  const requestMFA = (): void => {
    saveRedirect();
    const customProvider = getIdentityProvider(env, true);
    console.info(`MFA in progress... (${customProvider})`);
    requestMFASignIn(env, customProvider);
  };

  const mount = async() => {
  // If the MFA flow is triggered, stop the loading
    const hasValidMFA = await checkMFA();

    if (hasValidMFA) {
      hasValidDisclaimer ? loadAssets() : setDisclaimerOpen(true);
    } else {
      requestMFA();
    }
  };

  return {
    containerId,
    mount,
    unmount,
    hasValidDisclaimer,
    isDisclaimerOpen,
    setDisclaimerOpen,
  };
};
