import { Loader, LoaderSize } from 'dap-ui-components';
import BAHPage from 'pages/BAH';
import CFTPage from 'pages/CFT';
import CAWPage from 'pages/CorporateActions/CAW';
import CBOPage from 'pages/CorporateActions/CBO';
import CEPPage from 'pages/CorporateActions/CEP';
import CEQPage from 'pages/CorporateActions/CEQ';
import CETPage from 'pages/CorporateActions/CET';
import CorporateActionsPage from 'pages/CorporateActions/CorporateActions';
import Dashboard from 'pages/Dashboard';
import EADPage from 'pages/EAD';
import ETPPage from 'pages/ETP';
import FIAPage from 'pages/FIA';
import LOAPage from 'pages/LOA';
import MLPPage from 'pages/MLP';
import MOBPage from 'pages/MOB';
import RGUPage from 'pages/RGU';
import SAPPage from 'pages/SAP';
import SDAPage from 'pages/SDA';
import SDLPage from 'pages/SDL';
import TermsConditions from 'pages/TermsConditions/TermsConditions';
import TermsOfUse from 'pages/TermsOfUse/TermsOfUse';
import UAMPage from 'pages/UAM';
import UCRPage from 'pages/UCR';
import { FC, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import { ROUTES } from './config';

const AppRoutes: FC = () => {
  const applyWildCard = (route: string) => `${route}/*`;

  return (
    <Suspense fallback={<Loader size={LoaderSize.V56} />}>
      <Routes>
        <Route
          path={ROUTES.HOME}
          element={<Dashboard />}
        />

        <Route
          path={ROUTES.CORPORATE_ACTIONS}
          element={<CorporateActionsPage />}
        />

        <Route
          path={applyWildCard(ROUTES.CET)}
          element={<CETPage />}
        />

        <Route
          path={applyWildCard(ROUTES.CEP)}
          element={<CEPPage />}
        />

        <Route
          path={applyWildCard(ROUTES.CEQ)}
          element={<CEQPage />}
        />

        <Route
          path={applyWildCard(ROUTES.CBO)}
          element={<CBOPage />}
        />

        <Route
          path={applyWildCard(ROUTES.EAD)}
          element={<EADPage />}
        />

        <Route
          path={applyWildCard(ROUTES.RGU)}
          element={<RGUPage />}
        />

        <Route
          path={applyWildCard(ROUTES.LOA)}
          element={<LOAPage />}
        />

        <Route
          path={applyWildCard(ROUTES.MLP)}
          element={<MLPPage />}
        />

        <Route
          path={applyWildCard(ROUTES.UAM)}
          element={<UAMPage />}
        />

        { /* TODO: remove it after the UAM release. */ }
        <Route
          path={applyWildCard(ROUTES.UAM_CR)}
          element={<UCRPage />}
        />

        <Route
          path={applyWildCard(ROUTES.CAW)}
          element={<CAWPage />}
        />

        <Route
          path={applyWildCard(ROUTES.ETP)}
          element={<ETPPage />}
        />

        <Route
          path={applyWildCard(ROUTES.BAH)}
          element={<BAHPage />}
        />

        <Route
          path={applyWildCard(ROUTES.FIA)}
          element={<FIAPage />}
        />

        <Route
          path={applyWildCard(ROUTES.SAP)}
          element={<SAPPage />}
        />

        <Route
          path={applyWildCard(ROUTES.SDL)}
          element={<SDLPage />}
        />

        <Route
          path={applyWildCard(ROUTES.CFT)}
          element={<CFTPage />}
        />

        <Route
          path={applyWildCard(ROUTES.SDA)}
          element={<SDAPage />}
        />

        <Route
          path={applyWildCard(ROUTES.MOB)}
          element={<MOBPage />}
        />

        <Route
          path={ROUTES.TERMS_CONDITIONS}
          element={<TermsConditions />}
        />

        <Route
          path={ROUTES.TERMS_OF_USE}
          element={<TermsOfUse />}
        />
      </Routes>
    </Suspense>
  );
};

export default AppRoutes;
