import {
  Button,
  ButtonSeverity,
  ButtonVariant,
  HorizontalFlex,
  Link,
  Modal,
  ModalSize,
  Notify,
  NotifyVariant,
  Space,
  Textarea,
  VerticalFlex,
} from 'dap-ui-components';
import {
  decodeToken,
  generateFakeIdToken,
  getMockedAccessToken,
  getMockedIdToken,
  removeMockedTokens,
  setMockedAccessToken,
  setMockedIdToken,
} from 'helpers/authHelper';
import { useTranslate } from 'i18n';
import { AccessToken, IdToken } from 'models';
import { FC, useState } from 'react';

export interface SettingsModalProps {
  onClose: () => void;
}

const SettingsModal: FC<SettingsModalProps> = ({ onClose }) => {
  const t = useTranslate();
  const [idTokenString, setIdTokenString] = useState(getMockedIdToken());
  const [accessTokenString, setAccessTokenString] = useState(getMockedAccessToken());
  const [isLoading, setLoading] = useState(false);
  const [errorMap, setErrorMap] = useState<Map<string, string>>(new Map());

  const handleSubmit = async() => {
    const newErrorMap: Map<string, string> = new Map();

    if (idTokenString && !decodeToken<IdToken>(idTokenString)) {
      newErrorMap.set('idToken', t('settings.idTokenError'));
    }

    if (accessTokenString && !decodeToken<AccessToken>(accessTokenString)) {
      newErrorMap.set('accessToken', t('settings.accessTokenError'));
    }

    setErrorMap(newErrorMap);

    // Refresh the page to clean possible in-memory data of the apps
    if (!newErrorMap.size) {
      setMockedIdToken(idTokenString);
      setMockedAccessToken(accessTokenString);
      setLoading(true);
      window.location.href = window.location.origin;
    }
  };

  const handleRemove = () => {
    removeMockedTokens();
    setLoading(true);
    window.location.href = window.location.origin;
  };

  return (
    <Modal
      title={t('settings.title')}
      body={<>
        <VerticalFlex $gap={Space.V24}>
          <Notify
            variant={NotifyVariant.WARNING}
            text={t('settings.warning')}
            showLessLabel={t('common.showMore')}
            showMoreLabel={t('common.showLess')}
          />

          <VerticalFlex $gap={Space.V8}>
            <Textarea
              value={idTokenString}
              label={t('settings.idTokenLabel')}
              placeholder={t('settings.idTokenPlaceholder')}
              height={200}
              errorText={errorMap.get('idToken')}
              onChange={setIdTokenString}
            />

            <HorizontalFlex
              $gap={Space.V8}
              $justify="flex-end"
            >
              <Link
                label={t('settings.generateButton')}
                title={t('settings.generateHelpText')}
                onClick={async() => setIdTokenString(await generateFakeIdToken())}
              />

              <Link
                label={t('common.clearButton')}
                title={t('settings.clearHelpText')}
                onClick={() => setIdTokenString('')}
              />
            </HorizontalFlex>
          </VerticalFlex>

          <VerticalFlex $gap={Space.V8}>
            <Textarea
              value={accessTokenString}
              label={t('settings.accessTokenLabel')}
              placeholder={t('settings.accessTokenPlaceholder')}
              height={200}
              errorText={errorMap.get('accessToken')}
              onChange={setAccessTokenString}
            />

            <HorizontalFlex $justify="flex-end">
              <Link
                label={t('settings.clearButton')}
                title={t('settings.clearHelpText')}
                onClick={() => setAccessTokenString('')}
              />
            </HorizontalFlex>
          </VerticalFlex>
        </VerticalFlex>
      </>}
      footer={
        <HorizontalFlex
          $gap={Space.V8}
          $justify="flex-end"
        >
          <Button
            label={t('common.cancelButton')}
            variant={ButtonVariant.GHOST}
            onClick={onClose}
          />

          <Button
            label={t('settings.removeButton')}
            variant={ButtonVariant.SECONDARY}
            severity={ButtonSeverity.DANGER}
            disabled={isLoading}
            onClick={handleRemove}
          />

          <Button
            label={t('common.saveButton')}
            disabled={isLoading}
            onClick={handleSubmit}
          />
        </HorizontalFlex>
      }
      size={ModalSize.L}
      closeTitle={t('common.close')}
      onClose={onClose}
    />
  );
};

export default SettingsModal;
