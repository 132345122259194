import { Footer, FooterList, HorizontalFlex, Link, Logo, MediumTextS, RegularTextS, Space } from 'dap-ui-components';
import { useTranslate } from 'i18n';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'routes/config';

const AppFooter: FC = () => {
  const t = useTranslate();
  const navigate = useNavigate();
  const year = new Date().getFullYear();

  return (
    <Footer>
      <Logo height={22} />

      <HorizontalFlex $gap={Space.V12}>
        <RegularTextS>
          © {year} Euronext
        </RegularTextS>

        <FooterList>
          <li>
            <MediumTextS>
              <Link
                label={t('footer.privacyPolicy')}
                href="https://www.euronext.com/en/privacy-statement"
                blank
              />
            </MediumTextS>
          </li>

          <li>
            <MediumTextS>
              <Link
                label={t('footer.termsAndConditions')}
                onClick={() => navigate(ROUTES.TERMS_CONDITIONS)}
              />
            </MediumTextS>
          </li>

          <li>
            <MediumTextS>
              <Link
                label={t('footer.termsOfUse')}
                onClick={() => navigate(ROUTES.TERMS_OF_USE)}
              />
            </MediumTextS>
          </li>
        </FooterList>
      </HorizontalFlex>
    </Footer>
  );
};

export default AppFooter;
