import { AppUser, IdToken, UAMUser } from 'models';

const getUAMdata = (customUAM: string): UAMUser => {
  try {
    return JSON.parse(customUAM);
  } catch (error) {
    return new UAMUser();
  }
};

const getLegacyRoles = (customRoleString: string | undefined): string[] => {
  if (!customRoleString) {
    return [];
  } else if (customRoleString.indexOf('[') === 0) {
    return customRoleString.slice(1, -1).split(',');
  } else {
    return [customRoleString];
  }
};

export const getUserData = (idToken: IdToken): AppUser => {
  const uamData = getUAMdata(idToken['custom:uam'] || '');

  return {
    ...uamData,
    email: idToken.email,
    myEuronextApps: uamData.myEuronextApps || [],
    legacyRoles: getLegacyRoles(idToken['custom:role']),
  } as AppUser;
};
