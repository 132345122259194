import { setupAPI } from 'api';
import { AppEnv, AppWindow } from 'models';
import { createRoot } from 'react-dom/client';
import store from 'store';
import { updateEnv } from 'store/core/slice';
import packageJson from '../package.json';
import App from './App';
import AppProviders from './AppProviders';
import './i18n';
import reportWebVitals from './reportWebVitals';

console.info(`PORTAL v${packageJson.version}`);

// Add a global variable to make this app detectable by the micro-fe apps
(window as AppWindow).isDapPortal = true;

// TODO: refactor the envs to rename all them to "env.json" and remove this unuseful function
const getEnvName = () => {
  const host = window.location.host;
  const hostArr = host.split('.');
  const envName = hostArr[1];
  const suffix = window.location.host === 'localhost:3000' ? '' : `-${envName}`;
  return `/env${suffix}.json`;
};

fetch(getEnvName()).then(async response => {
  const env = await response.json() as AppEnv;
  console.info('PORTAL env', env);
  store.dispatch(updateEnv(env));
  setupAPI(env.uamApiUrl);

  const root = createRoot(document.getElementById('root') as HTMLDivElement);
  root.render((
    <AppProviders>
      <App />
    </AppProviders>
  ));
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
