import { Button, ErrorState, InformationIcon } from 'dap-ui-components';
import { useTranslate } from 'i18n';
import { FC } from 'react';

export interface CommonErrorStateProps {
  description: string;
  onReload: () => void;
}

const CommonErrorState: FC<CommonErrorStateProps> = ({
  description,
  onReload,
}) => {
  const t = useTranslate();

  return (
    <ErrorState
      icon={InformationIcon}
      title={t('errorBoundary.title')}
      description={description}
      actions={(
        <Button
          label={t('common.refreshButton')}
          onClick={onReload}
        />
      )}
    />
  );
};

export default CommonErrorState;
