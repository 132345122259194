import Widget from 'components/Widget/Widget';
import { EmptyState, LayoutContainer, ResponsiveGrid, useRestoreScrollbar } from 'dap-ui-components';
import { canShowWidget } from 'helpers/authHelper';
import { useTranslate } from 'i18n';
import { WidgetTrigram } from 'models';
import { FC, useEffect } from 'react';
import { ROUTES } from 'routes/config';
import { selectEnv, selectUser } from 'store/core/slice';
import { useAppSelector } from 'store/types';

interface DashboardWidget {
  title: string;
  route: string;
  trigrams: WidgetTrigram[];
}

const Dashboard: FC = () => {
  const t = useTranslate();
  const { uamEnabledApps } = useAppSelector(selectEnv);
  const user = useAppSelector(selectUser);
  const restoreScrollbar = useRestoreScrollbar();

  useEffect(() => {
    restoreScrollbar();
  }, []);

  const widgets: DashboardWidget[] = [
    {
      title: t('dashboard.corporateActions.title'),
      route: ROUTES.CORPORATE_ACTIONS,
      trigrams: [WidgetTrigram.CEQ, WidgetTrigram.CET, WidgetTrigram.CBO, WidgetTrigram.CAW],
    },
    {
      title: t(`apps.${WidgetTrigram.EAD}`),
      route: ROUTES.EAD,
      trigrams: [WidgetTrigram.EAD],
    },
    {
      title: t(`apps.${WidgetTrigram.RGU}`),
      route: ROUTES.RGU,
      trigrams: [WidgetTrigram.RGU],
    },
    {
      title: t(`apps.${WidgetTrigram.LOA}`),
      route: ROUTES.LOA,
      trigrams: [WidgetTrigram.LOA],
    },
    {
      title: t(`apps.${WidgetTrigram.MLP}`),
      route: ROUTES.MLP,
      trigrams: [WidgetTrigram.MLP],
    },
    {
      title: t(`apps.${WidgetTrigram.UAM}`),
      route: ROUTES.UAM,
      trigrams: [WidgetTrigram.UAM],
    },
    { // TODO: remove it after the UAM release.
      title: t(`apps.${WidgetTrigram.UCR}`),
      route: ROUTES.UAM_CR,
      trigrams: [WidgetTrigram.UCR],
    },
    {
      title: t(`apps.${WidgetTrigram.ETP}`),
      route: ROUTES.ETP,
      trigrams: [WidgetTrigram.ETP],
    },
    {
      title: t(`apps.${WidgetTrigram.BAH}`),
      route: ROUTES.BAH,
      trigrams: [WidgetTrigram.FIA, WidgetTrigram.SDL, WidgetTrigram.SAP],
    },
    {
      title: t(`apps.${WidgetTrigram.CFT}`),
      route: ROUTES.CFT,
      trigrams: [WidgetTrigram.CFT],
    },
    {
      title: t(`apps.${WidgetTrigram.SDA}`),
      route: ROUTES.SDA,
      trigrams: [WidgetTrigram.SDA],
    },
    {
      title: t(`apps.${WidgetTrigram.MOB}`),
      route: ROUTES.MOB,
      trigrams: [WidgetTrigram.MOB],
    },
  ].filter(({ trigrams }) => trigrams.some(id => canShowWidget(id, user, uamEnabledApps)));

  return (
    <LayoutContainer>
      <ResponsiveGrid
        $m="repeat(2, 1fr)"
        $l="repeat(3, 1fr)"
      >
        {widgets.map(({ title, route }) => (
          <Widget
            key={title}
            title={title}
            route={route}
          />
        ))}
      </ResponsiveGrid>

      {widgets.length === 0 && (
        <EmptyState
          title={t('dashboard.noWidgets')}
        />
      )}
    </LayoutContainer>
  );
};

export default Dashboard;
