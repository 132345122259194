import { hasMFARetryExceeded } from 'helpers/mfaHelper';
import { getRedirect, removeRedirect } from 'helpers/redirectHelper';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

interface WidgetRedirect {
  entryFrom: string;
  entryTo: string;
  exitFrom: string;
  exitTo: string;
}

interface ActiveRedirect extends WidgetRedirect {
  origin: string;
}

// Regext to define a generic id
const id = '[^/]*';

// Config of the global cross-widget navigation and redirects
const widgetRedirects: WidgetRedirect[] = [
  // UAM: User personal details
  {
    entryFrom: '.+',
    entryTo: '/uam/users/personal-details',
    exitFrom: '/uam/users/personal-details(.+)?',
    exitTo: '/uam/users/personal-details/back',
  },
  // MOB: New company flow
  {
    entryFrom: '/onboarding/new-request',
    entryTo: '/uam/new-company',
    exitFrom: '/uam/new-company',
    exitTo: `/uam(/company/${id}/details)?`, // home or company details
  },
  // MOB: Company edit flow
  {
    entryFrom: `/onboarding/request-initiation/${id}`,
    entryTo: `/uam/company/${id}/modify`,
    exitFrom: `/uam/company/${id}/modify`,
    exitTo: `/uam/company/${id}/details`,
  },
  {
    entryFrom: '/onboarding/new-request',
    entryTo: `/uam/company/${id}/modify`,
    exitFrom: `/uam/company/${id}/modify`,
    exitTo: `/uam/company/${id}/details`,
  },
  {
    entryFrom: `/onboarding/company-overview/${id}`,
    entryTo: `/uam/company/${id}/modify`,
    exitFrom: `/uam/company/${id}/modify`,
    exitTo: `/uam/company/${id}/details`,
  },
  // MOB: New contact flow
  {
    entryFrom: '/onboarding/new-request',
    entryTo: `/uam/company/${id}/new-contact`,
    exitFrom: `/uam/company/${id}/new-contact`,
    exitTo: `/uam/company/${id}/contacts`,
  },
  {
    entryFrom: `/onboarding/request-initiation/${id}/mandatory-contacts`,
    entryTo: `/uam/company/${id}/new-contact`,
    exitFrom: `/uam/company/${id}/new-contact`,
    exitTo: `/uam/company/${id}/contacts`,
  },
  {
    entryFrom: `/onboarding/request-admission/${id}/participation-information/users-and-contacts`,
    entryTo: `/uam/company/${id}/new-contact`,
    exitFrom: `/uam/company/${id}/new-contact`,
    exitTo: `/uam/company/${id}/contacts`,
  },
  {
    entryFrom: `/onboarding/modification/mandatory-contacts/${id}`,
    entryTo: `/uam/company/${id}/new-contact`,
    exitFrom: `/uam/company/${id}/new-contact`,
    exitTo: `/uam/company/${id}/contacts`,
  },
  {
    entryFrom: `/onboarding/modification/users-and-contacts/${id}`,
    entryTo: `/uam/company/${id}/new-contact`,
    exitFrom: `/uam/company/${id}/new-contact`,
    exitTo: `/uam/company/${id}/contacts`,
  },
  // MOB: Contact edit flow
  {
    entryFrom: `/onboarding/request-initiation/${id}/mandatory-contacts`,
    entryTo: `/uam/company/${id}/contact-edit/${id}`,
    exitFrom: `/uam/company/${id}/contact-edit/${id}`,
    exitTo: `/uam/company/${id}/contacts/${id}`,
  },
  {
    entryFrom: `/onboarding/request-admission/${id}/participation-information/users-and-contacts`,
    entryTo: `/uam/company/${id}/contact-edit/${id}`,
    exitFrom: `/uam/company/${id}/contact-edit/${id}`,
    exitTo: `/uam/company/${id}/contacts/${id}`,
  },
  {
    entryFrom: '/onboarding/new-request',
    entryTo: `/uam/company/${id}/contact-edit/${id}`,
    exitFrom: `/uam/company/${id}/contact-edit/${id}`,
    exitTo: `/uam/company/${id}/contacts/${id}`,
  },
  {
    entryFrom: `/onboarding/modification/mandatory-contacts/${id}`,
    entryTo: `/uam/company/${id}/contact-edit/${id}`,
    exitFrom: `/uam/company/${id}/contact-edit/${id}`,
    exitTo: `/uam/company/${id}/contacts/${id}`,
  },
  {
    entryFrom: `/onboarding/modification/users-and-contacts/${id}`,
    entryTo: `/uam/company/${id}/contact-edit/${id}`,
    exitFrom: `/uam/company/${id}/contact-edit/${id}`,
    exitTo: `/uam/company/${id}/contacts/${id}`,
  },
  // CA-ETF/CA-ETP cross navigation
  {
    entryFrom: '/corporate-actions-etp(/)?',
    entryTo: `/corporate-actions-etf/follow-up/${id}/${id}`,
    exitFrom: '/corporate-actions-etf/follow-up/.+',
    exitTo: '/corporate-actions-etf/request-list',
  },
];

/*
 * This hook is responsible to manage the redirects between the different widgets.
 * The typical scenario is when a widget navigate to another one in order to complete some task
 * and then, the user should be redirected back to the origin widget.
 */
export const useCrossWidgetRedirect = () => {
  const [isRedirecting, setRedirecting] = useState(false);

  const checkRoute = (rule: string, route: string): boolean => {
    return new RegExp(`^${rule}$`).test(route);
  };

  const getActiveRedirects = (): ActiveRedirect[] => {
    const sessionRedirects = window.sessionStorage.getItem('activeRedirects');
    return sessionRedirects ? JSON.parse(sessionRedirects) : [];
  };

  const setActiveRedirects = (value: ActiveRedirect[]) => {
    window.sessionStorage.setItem('activeRedirects', JSON.stringify(value));
  };

  // Register an event to control the widgets redirect (currently it performs only a path change).
  useEffect(() => {
    const listener = (event: Event) => {
      const from = window.location.pathname;
      const to = (event as CustomEvent).detail;
      const activeRedirects = getActiveRedirects();

      // Check if there are redirects to set as active
      const newRedirects: ActiveRedirect[] = widgetRedirects
        .filter(item => {
          const matchFrom = checkRoute(item.entryFrom, from);
          const matchTo = checkRoute(item.entryTo, to);
          const isNew = !activeRedirects.some(ar => ar.entryFrom === item.entryFrom && ar.entryTo === item.entryTo);
          return matchFrom && matchTo && isNew;
        })
        .map(item => ({ ...item, origin: from }));

      setActiveRedirects(activeRedirects.concat(newRedirects));
      setRedirecting(true);
      window.location.href = to;
    };

    window.addEventListener('portal-navigate', listener, false);
    return () => window.removeEventListener('portal-navigate', listener, false);
  }, []);

  // Create an loop to intercept the location pathname change executed by the child apps.
  // NOTE: the portal and the child apps are isolated, so the portal can't bind to default router changes.
  useEffect(() => {
    // Get the initial pathname
    const from = window.location.pathname;

    const checkInterval = setInterval(() => {
    // Get the current pathname
      const to = window.location.pathname;
      const activeRedirects = getActiveRedirects();

      // Check if there is a previously activated redirect
      const widgetRedirect = activeRedirects.find(item => {
        const matchFrom = checkRoute(item.exitFrom, from);
        const matchTo = checkRoute(item.exitTo, to);
        return matchFrom && matchTo;
      });

      if (widgetRedirect) {
        // Remove the triggered redirect
        const updatedRedirects = activeRedirects
          .filter(item => item !== widgetRedirect);

        setActiveRedirects(updatedRedirects);

        // Apply the redirect to the origin route
        console.info('Cross widget redirect:', widgetRedirect.origin);
        setRedirecting(true);
        window.location.href = widgetRedirect.origin;
      }
    }, 50);

    return () => clearInterval(checkInterval);
  }, []);

  return {
    isRedirecting,
    setRedirecting,
  };
};

/*
 * This hook is responsible to restore the last route when an external website redirect to the app.
 * The typical scenario is when a route is requested with the expired login.
 * The app track the route and redirect to the external login website.
 * Then, after the login success redirect, the app restore the tracked route.
 */
export const useExternalRedirect = () => {
  const navigate = useNavigate();

  return () => {
    const redirectPath = getRedirect();
    removeRedirect();

    if (redirectPath && !hasMFARetryExceeded()) {
      console.info('Redirect detected:', redirectPath);
      navigate(redirectPath);
    }
  };
};
