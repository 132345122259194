import { AxiosError } from 'axios';
import { useTranslate } from 'i18n';
import { useState } from 'react';

export const useCommonCover = <T extends (...args: any[]) => Promise<unknown>>(
  loadData: T,
  errorCodeMap?: Record<number, string>,
  initialLoading = true,
) => {
  const t = useTranslate();
  const [loading, setLoading] = useState(initialLoading);
  const [errorMessage, setErrorMessage] = useState('');

  const reloadData: (...args: Parameters<T>) => Promise<void> = async(...args) => {
    try {
      setLoading(true);
      await loadData(...args);
      setErrorMessage('');
    } catch (error) {
      const errorCode = (error as AxiosError)?.response?.status as number;
      const fallbackMessage = t(`apiErrors.${errorCode}`, t('common.genericError'));
      const errorsMessage = errorCodeMap?.[errorCode] || fallbackMessage;
      setErrorMessage(errorsMessage);
    } finally {
      setLoading(false);
    }
  };

  return {
    loading,
    errorMessage,
    reloadData,
  };
};
