import AppDisclaimer from 'components/AppDisclaimer/AppDisclaimer';
import AppPermissionError from 'components/AppPermissionError/AppPermissionError';
import { LayoutContainer, VerticalFlex } from 'dap-ui-components';
import { FC, useEffect } from 'react';
import { WidgetTrigram } from '../../models';
import { useRenderer } from './MicroFrontend.hooks';

export interface MicroFrontendProps {
  appId: WidgetTrigram;
  host: string;
  filterPublicUrl?: boolean;

  /**
   * @deprecated
   * Remove this prop and update your micro-fe app (index.tsx) setting the APP_NAME value matching the appId value.
   * Read the README "Migrate your app to the new dap-portal integration" section for the handling of this breaking change.
   */
  name?: string;

  /**
   * @deprecated
   * The MicroFrontend component pass automatically the needed configuration to the apps.
   * Remove this prop from your "pages/<app>" and update your micro-fe app (index.tsx) with the new passed config content.
   * Read the README "Migrate your app to the new dap-portal integration" section for the handling of this breaking change.
  */
  Config?: Record<string, any>;
}

const MicroFrontend: FC<MicroFrontendProps> = ({
  appId,
  host,
  filterPublicUrl,
  name,
  Config,
}) => {
  const {
    containerId,
    mount,
    unmount,
    hasValidDisclaimer,
    isDisclaimerOpen,
    setDisclaimerOpen,
  } = useRenderer(
    appId,
    host,
    filterPublicUrl,
    name,
    Config,
  );

  useEffect(() => {
    mount();
    return unmount();
  }, []);

  return (
    <>
      {isDisclaimerOpen && (
        <AppDisclaimer onClose={() => setDisclaimerOpen(false)} />
      )}

      {!hasValidDisclaimer && (
        <LayoutContainer>
          <AppPermissionError />
        </LayoutContainer>
      )}

      {hasValidDisclaimer && (
        <VerticalFlex
          id={containerId}
          $align="center"
          $justify="flex-start"
          $grow
        />
      )}
    </>
  );
};

export default MicroFrontend;
